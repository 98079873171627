/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../components/Layout'
import ThemeWrapper from '../components/Theme'
import { Column, Menu, ColumnWrapper, MenuWrap, MenuButton, ColumnWrap, Title, Subtitle, Text, Button, Image, Fullmap, FullmapWrap, FullmapCover, List, PriceWrap } from '@swp/components'
import SiteHeader from '../components/SiteHeader'

export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout >
        <SiteHeader set="" currentLanguage={undefined} />

        <Column className="--menu pb--08 pt--08" menu={true} name={"hlavicka"}>
          
          <Menu style={{"maxWidth":1506}} fullscreen={false}>
            
            <ColumnWrapper className="menu-logo-box">
            </ColumnWrapper>

            <MenuWrap >
              
              <MenuButton >
              </MenuButton>

            </MenuWrap>

          </Menu>

        </Column>


        <Column style={{"paddingTop":61,"paddingBottom":19,"backgroundColor":"rgba(196, 232, 188, 1)"}} name={"tbcpi879de"}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper >
              
              <Title className="title-box ff--1" content={"MUDr. IVONA PROKOPCOVÁ"}>
              </Title>

              <Subtitle className="subtitle-box ff--1" content={"Praktický lékař"}>
              </Subtitle>

              <Text className="text-box fs--18" style={{"maxWidth":650}} content={"<span style=\"color: var(--black); font-weight: normal;\">HERČÍKOVA 23, BRNO KRÁLOVO POLE&nbsp; &nbsp;|&nbsp; &nbsp;+420 541 218 456</span>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column style={{"marginTop":0,"paddingTop":28,"paddingBottom":5}} name={"798xqlydn5t"}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper >
              
              <Button className="btn-box" style={{"backgroundColor":"rgba(44,44,44,1)"}} use={"page"} href={"/#aktuality"} content={"KOMPLETNÍ AKTUALITY ZDE"}>
              </Button>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--60" name={"aktualiy"} layout={"l3"}>
          
          <ColumnWrap className="column__flex --center el--3" columns={"3"}>
            
            <ColumnWrapper >
              
              <Image style={{"maxWidth":80}} src={"https://cdn.swbpg.com/t/3564/7430a252055442fca06b576d2107ff0a_s=350x_.jpg"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" srcSet={"https://cdn.swbpg.com/t/3564/7430a252055442fca06b576d2107ff0a_s=350x_.jpg 350w"}>
              </Image>

              <Subtitle className="subtitle-box fs--18" content={"<br>Vzhledem ke chřipkové epidemii, prosím, noste do čekárny i ordinace respirátor, před každým vyšetřením je nutné se objednat na čas!<br>**<br><span style=\"color: rgb(14, 14, 14);\">nyní ordinujeme<br></span>**<br><span style=\"text-decoration-line: underline;\">Zástup v době dovolené:</span>&nbsp;pouze pro akutní stavy vyžadující osobní vyšetření (nemoc, PN )- ošetří MUDr. Pavel Poneš, ve svých ordinačních hodinách nejlépe po předchozí konzultaci. Tel.: 777 900 676<br>...<br>pondělní ordinace je určena především akutním nemocným a objednaným pracujícím,<br>objednané léky odešleme do 2 dnů<br>**<br><br>"}>
              </Subtitle>

              <Subtitle className="subtitle-box fs--18" content={"<br>Ke každému vyšetření se vždy nejlépe&nbsp; objednejte,&nbsp;<br><span style=\"font-weight: normal;\">akutní nemocní vždy 7:40-9:00, pondělí 13-15:00</span><br><br><br>"}>
              </Subtitle>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image style={{"maxWidth":80}} src={"https://cdn.swbpg.com/t/3564/993a8e9bb0784116962ce88e3c99b803_s=350x_.jpg"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" srcSet={"https://cdn.swbpg.com/t/3564/993a8e9bb0784116962ce88e3c99b803_s=350x_.jpg 350w"}>
              </Image>

              <Subtitle className="subtitle-box" content={"VYŠETŘENÍ"}>
              </Subtitle>

              <Subtitle className="subtitle-box fs--18" content={"<span style=\"color: rgb(249, 6, 6);\">Ke každému&nbsp;vyšetření je nutné se objednat, neakutní vždy!</span><br><span style=\"text-decoration-line: underline;\">Konzultace</span> výsledků 10:30-11:00<br>Recepty ze záznamníku/mailu odesíláme většinou během dne, max do 2-3 dnů.<br>**<br><span style=\"text-decoration-line: underline;\">Prevence</span> á 2 roky, vč. odběrů, nárok nutno ověřit u nás&nbsp;<br><br><span style=\"text-decoration-line: underline;\">Plánované operace</span>-domluva na plánu předoperačního&nbsp;vyšetření<br>&nbsp;je vždy nutná co nejdříve<br>**<br>ODBĚRY<br><br>přímo v ordinaci<span style=\"text-decoration-line: underline;\"> úterý </span>a <span style=\"text-decoration-line: underline;\">pátek, 7:00-8:00</span>, ostatní dny odběrová místnost Medila.<br><br>&nbsp;(www.medila.cz)-vždy po domluvě, nutná žádanka (elektronicky posíláme na místo, nebo osobně)<br>**<br>VÝPIS Z DOKUMENTACE<br>do 10 pracovních dní, 400 kč&nbsp;<br><br>"}>
              </Subtitle>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image style={{"maxWidth":80}} src={"https://cdn.swbpg.com/t/3564/993a8e9bb0784116962ce88e3c99b803_s=350x_.jpg"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" srcSet={"https://cdn.swbpg.com/t/3564/993a8e9bb0784116962ce88e3c99b803_s=350x_.jpg 350w"}>
              </Image>

              <Subtitle className="subtitle-box" content={"&nbsp;PACIENTI-registrace"}>
              </Subtitle>

              <Subtitle className="subtitle-box fs--18" content={"Z kapacitních důvodů <span style=\"text-decoration-line: underline;\">přijímáme přednostně pacienty bez lékaře</span>, a to z nejbližšího okolí (Kr.Pole apod.)&nbsp;<br><span style=\"text-decoration-line: underline;\">Nutno po předchozím objednání.&nbsp;</span><br><br>"}>
              </Subtitle>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image style={{"maxWidth":80}} src={"https://cdn.swbpg.com/t/3564/993a8e9bb0784116962ce88e3c99b803_s=350x_.jpg"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" srcSet={"https://cdn.swbpg.com/t/3564/993a8e9bb0784116962ce88e3c99b803_s=350x_.jpg 350w"}>
              </Image>

              <Subtitle className="subtitle-box" content={"OČKOVÁNÍ"}>
              </Subtitle>

              <Subtitle className="subtitle-box fs--18" content={"<span style=\"text-decoration-line: underline;\">Očkování proti černému kašli (vakcína vč.záškrtu a tetanu) po objednání, objednáme vakcínu, hradí pacient</span><br><span style=\"text-decoration-line: underline;\">1000,- blíže v ordinaci</span><br>*<br><span style=\"text-decoration-line: underline;\">&nbsp;očkování proti klíšťové encefalitis ( látka FSME ) cena od 1.10.2024 1200,-</span><br><br><br>"}>
              </Subtitle>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image style={{"maxWidth":80}} src={"https://cdn.swbpg.com/t/3564/993a8e9bb0784116962ce88e3c99b803_s=350x_.jpg"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" srcSet={"https://cdn.swbpg.com/t/3564/993a8e9bb0784116962ce88e3c99b803_s=350x_.jpg 350w"}>
              </Image>

              <Subtitle className="subtitle-box" content={"EPIDEMIE"}>
              </Subtitle>

              <Subtitle className="subtitle-box fs--18" content={"<span style=\"font-weight: 400;\">Epidemie respiračních onemocnění- používejte roušku či respirátor</span><br>"}>
              </Subtitle>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image style={{"maxWidth":80}} src={"https://cdn.swbpg.com/t/3564/7430a252055442fca06b576d2107ff0a_s=350x_.jpg"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" srcSet={"https://cdn.swbpg.com/t/3564/7430a252055442fca06b576d2107ff0a_s=350x_.jpg 350w"}>
              </Image>

              <Subtitle className="subtitle-box" content={"AKUTNÍ PACIENTI"}>
              </Subtitle>

              <Subtitle className="subtitle-box fs--18" content={"<span style=\"font-weight: 400;\">Informace k akutnímu vyšetření pacienta: dle stavu mají přednost akutní a objednaní.</span><br><span style=\"text-decoration-line: underline;\">NEMOCNÍ:</span><br>pondělí 13:00-15:00<br>ostatní dny 7:45-9:00<br>je vhodné se vždy objednat!<br>"}>
              </Subtitle>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Fullmap className="--style2" style={{"marginBottom":14.1796875,"paddingBottom":0,"backgroundColor":"rgba(255, 255, 255, 1)"}} name={"adresa"}>
          
          <FullmapWrap className="pb--20 pt--20">
            
            <Subtitle className="subtitle-box subtitle-box--center" content={"<span style=\"color: rgb(0, 0, 0);\">HERČÍKOVA 23, BRNO KRÁLOVO POLE</span>"}>
            </Subtitle>

            <Subtitle className="subtitle-box subtitle-box--center ff--1 fs--18" style={{"maxWidth":724}} content={"<span style=\"color: rgb(0, 0, 0);\"><span style=\"font-weight: normal;\">1. patro lékařského střediska, vchod vlevo vedle lékárny</span></span><br>"}>
            </Subtitle>

          </FullmapWrap>

          <FullmapWrap className="--full">
            
            <FullmapCover zoom={"15"} place={"Herčíkova 23"}>
            </FullmapCover>

          </FullmapWrap>

        </Fullmap>


        <Column className="--l2R1 bg--center mb--0 pb--12 pt--0" style={{"paddingTop":null,"marginBottom":null,"paddingBottom":null,"backgroundColor":"var(--color-variable-1)"}} name={"kontakt"}>
          
          <ColumnWrap className="column__flex --full --center el--3 pl--40 pr--40" style={{"paddingTop":83,"paddingLeft":null,"marginBottom":0,"paddingRight":null,"paddingBottom":46,"backgroundColor":"rgba(196, 232, 188, 1)"}} columns={"3"}>
            
            <ColumnWrapper className="--left">
              
              <Image style={{"maxWidth":236,"marginTop":0}} src={"https://cdn.swbpg.com/t/3564/cf179fdbbfca4d8aa0a3a7d4717aef19_s=350x_.jpg"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" srcSet={"https://cdn.swbpg.com/t/3564/cf179fdbbfca4d8aa0a3a7d4717aef19_s=350x_.jpg 350w"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper className="--center" style={{"marginTop":0,"paddingTop":0,"marginBottom":0,"paddingBottom":0}}>
              
              <Title className="title-box ff--1 fs--26 w--900 title-box--invert ls--002 lh--1 mt--0 pb--0 pt--0" style={{"marginTop":null,"paddingTop":null,"paddingBottom":null}} content={"<span style=\"color: rgb(0, 0, 0);\">MUDr. IVONA PROKOPCOVÁ</span>"}>
              </Title>

              <Subtitle className="subtitle-box fs--18 w--300 ls--50 mb--02" style={{"marginBottom":null}} content={"<span style=\"color: rgb(0, 0, 0);\">PRAKTICKÝ LÉKAŘ</span>"}>
              </Subtitle>

              <Button className="btn-box mt--25" style={{"marginBottom":29,"backgroundColor":"var(--black)"}} use={"page"} href={"/#aktualiy"} content={"<span style=\"color: rgb(255, 255, 255);\">PLÁNOVANÁ<br>DOVOLENÁ &gt;</span><br>"}>
              </Button>

              <Text className="text-box fs--18" content={"<span style=\"color: var(--black); font-weight: normal;\">Herčíkova 23, Brno 612 00<br>Česká republika<br><br>ordinace@iprokopcova.cz<br>+420 541 218 456</span><br>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="--center" style={{"marginTop":0,"paddingTop":0,"marginBottom":0,"paddingBottom":0}}>
              
              <Title className="title-box ff--1 fs--26 w--900 title-box--invert ls--002 lh--1 mt--0 pb--0 pt--0" style={{"marginTop":null,"paddingTop":null,"paddingBottom":null}} content={"<span style=\"color: rgb(0, 0, 0);\">SLUŽBY</span>"}>
              </Title>

              <List className="text-box text-box--left ff--1 fs--18" content={"<li><span style=\"color: var(--black); background-color: transparent; letter-spacing: -0.03em; font-weight: normal;\">Preventivní prohlídky, lékařská péče</span></li><li><span style=\"font-weight: normal;\"><span style=\"color: var(--black); background-color: transparent; letter-spacing: -0.03em;\">Očkování</span><br></span></li><li><span style=\"color: var(--black); background-color: transparent; letter-spacing: -0.03em; font-weight: normal;\">Prevence civilizačních chorob</span></li><li><span style=\"color: var(--black); background-color: transparent; letter-spacing: -0.03em; font-weight: normal;\">Potvrzení o způsobilosti řízení motorových vozidel</span></li><li><span style=\"color: var(--black); background-color: transparent; letter-spacing: -0.03em; font-weight: normal;\">Měření tlaku, odběry, stanovení CRP,&nbsp; testování covid</span></li><li><span style=\"color: var(--black); background-color: transparent; letter-spacing: -0.03em; font-weight: normal;\">pulzní oxymetrie, stanovení glykemie&nbsp;</span></li><li><span style=\"color: var(--black); background-color: transparent; letter-spacing: -0.03em; font-weight: normal;\">Poradenství</span></li><li><span style=\"color: var(--black); background-color: transparent; letter-spacing: -0.03em; font-weight: normal;\">Možnost zaslání e-receptu (SMS, e-mail)</span></li>"} subtype={"unordered"}>
              </List>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--60" name={"ordinacnihodiny"}>
          
          <ColumnWrap className="column__flex el--2" columns={"2"}>
            
            <ColumnWrapper >
              
              <Title className="title-box title-box--center ff--1 fs--24 w--400 swpf--uppercase ls--20" style={{"marginBottom":0,"paddingBottom":29}} content={"<span style=\"font-weight: bold;\">upozornění pro pacienty</span>"}>
              </Title>

              <PriceWrap className="el--1" style={{"maxWidth":531,"paddingLeft":24,"paddingRight":0,"backgroundColor":"rgba(180,237,190,1)"}} columns={"1"}>
                
                <ColumnWrapper className="--center">
                  
                  <Text className="text-box text-box--left fs--18" style={{"paddingRight":0}} content={"<span style=\"color: rgb(0, 0, 0);\"><span style=\"font-weight: normal;\">Před vstupem do ordinace, prosíme, nachystat</span><span style=\"font-weight: 400;\"><br></span></span><span style=\"color: rgb(0, 0, 0);\">KARTIČKU POJIŠŤOVNY!</span><br>"}>
                  </Text>

                </ColumnWrapper>

              </PriceWrap>

              <PriceWrap className="el--1" style={{"maxWidth":531,"paddingLeft":24,"paddingRight":0,"backgroundColor":"rgba(180,237,190,1)"}} columns={"1"}>
                
                <ColumnWrapper className="--center">
                  
                  <Text className="text-box text-box--left fs--18" style={{"paddingRight":0}} content={"<span style=\"color: var(--black);\">V úterý a pátek 7:00 - 8:00 probíhají odběry<br><span style=\"font-weight: normal;\">ostatní dny odběry v odběrové místnosti Medila, Palackého 137 a ostatní místa- s žádankou (možno i elektronickou od nás)<br>(vždy po předchozí domluvě) www.medila.cz</span></span><br>"}>
                  </Text>

                </ColumnWrapper>

              </PriceWrap>

              <PriceWrap className="el--1" style={{"maxWidth":531,"paddingLeft":24,"paddingRight":0,"backgroundColor":"rgba(180,237,190,1)"}} columns={"1"}>
                
                <ColumnWrapper className="--center">
                  
                  <Text className="text-box text-box--left ff--1 fs--18" style={{"paddingRight":0}} content={"<span style=\"font-weight: normal; color: rgb(0, 0, 0);\">Využijte prosím možnost se k vyšetření objednat<br></span><span style=\"color: var(--black);\">+420 541 218 456;&nbsp; na recepty lze využít vzkaz na záznamník<br>objednané léky napíšeme do 2-3 dnů, není-li urgence</span><br>"}>
                  </Text>

                </ColumnWrapper>

              </PriceWrap>

              <PriceWrap className="el--1" style={{"maxWidth":531,"paddingLeft":24,"marginBottom":0,"paddingRight":0,"paddingBottom":0,"backgroundColor":"rgba(223,34,38,1)"}} columns={"1"}>
                
                <ColumnWrapper className="--center" style={{"maxWidth":444,"marginTop":0,"paddingTop":21,"marginBottom":0,"paddingBottom":34}}>
                  
                  <Text className="text-box text-box--center ff--1 fs--18" style={{"maxWidth":526,"paddingLeft":0,"paddingRight":0,"paddingBottom":0}} content={"<span style=\"color: var(--white);\">POŘADÍ PACIENTŮ URČUJE LÉKAŘ!<br><span style=\"font-weight: normal;\">Přednost mají aktutní stavy, těhotné ženy<br>a objednaní pacienti.</span></span><br>"}>
                  </Text>

                </ColumnWrapper>

              </PriceWrap>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Title className="title-box title-box--center ff--1 fs--24 w--400 swpf--uppercase ls--20" style={{"marginBottom":0,"paddingBottom":30}} content={"<span style=\"font-weight: bold;\">ORDINAČNÍ HODINY</span>"}>
              </Title>

              <PriceWrap style={{"backgroundColor":"#f4f4f4"}}>
                
                <ColumnWrapper >
                  
                  <Text className="text-box fs--18" content={"<span style=\"color: var(--black);\">PONDĚLÍ</span>"}>
                  </Text>

                  <Text className="text-box" content={"Jen vyzvané prohlídky PP, PLP:"}>
                  </Text>

                </ColumnWrapper>

                <ColumnWrapper className="--right">
                  
                  <Text className="text-box fs--18" content={"<span style=\"color: var(--black);\">13 - 18</span>"}>
                  </Text>

                  <Text className="text-box" content={"10 - 13"}>
                  </Text>

                </ColumnWrapper>

              </PriceWrap>

              <PriceWrap style={{"backgroundColor":"#f4f4f4"}}>
                
                <ColumnWrapper >
                  
                  <Text className="text-box fs--18" content={"<span style=\"color: var(--black);\">ÚTERÝ</span>"}>
                  </Text>

                  <Text className="text-box" content={"Jen vyzvané prohlídky:"}>
                  </Text>

                </ColumnWrapper>

                <ColumnWrapper className="--right">
                  
                  <Text className="text-box fs--18" content={"<span style=\"color: var(--black);\">7 - 12</span>"}>
                  </Text>

                  <Text className="text-box" content={"12 - 13"}>
                  </Text>

                </ColumnWrapper>

              </PriceWrap>

              <PriceWrap style={{"backgroundColor":"#f4f4f4"}}>
                
                <ColumnWrapper >
                  
                  <Text className="text-box fs--18" content={"<span style=\"color: var(--black);\">STŘEDA</span>"}>
                  </Text>

                </ColumnWrapper>

                <ColumnWrapper className="--right">
                  
                  <Text className="text-box fs--18" content={"<span style=\"color: var(--black);\">7 - 12</span>"}>
                  </Text>

                </ColumnWrapper>

              </PriceWrap>

              <PriceWrap style={{"backgroundColor":"#f4f4f4"}}>
                
                <ColumnWrapper >
                  
                  <Text className="text-box fs--18" content={"<span style=\"color: var(--black);\">ČTVRTEK</span>"}>
                  </Text>

                  <Text className="text-box" content={"Jen vyzvané prohlídky:"}>
                  </Text>

                </ColumnWrapper>

                <ColumnWrapper className="--right">
                  
                  <Text className="text-box fs--18" content={"<span style=\"color: var(--black);\">7 - 12</span>"}>
                  </Text>

                  <Text className="text-box" content={"12 - 13"}>
                  </Text>

                </ColumnWrapper>

              </PriceWrap>

              <PriceWrap style={{"backgroundColor":"#f4f4f4"}}>
                
                <ColumnWrapper >
                  
                  <Text className="text-box fs--18" content={"<span style=\"color: var(--black);\">PÁTEK- ordinuje MUDr. Doležel</span>"}>
                  </Text>

                </ColumnWrapper>

                <ColumnWrapper className="--right">
                  
                  <Text className="text-box fs--18" content={"<span style=\"color: var(--black);\">7 - 12</span>"}>
                  </Text>

                </ColumnWrapper>

              </PriceWrap>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="--center" style={{"marginTop":0,"paddingTop":0,"paddingBottom":0,"backgroundColor":"rgba(214,239,208,1)"}} name={"aktualitynadpis"}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper className="--left" style={{"marginTop":33,"marginBottom":8,"paddingBottom":26}}>
              
              <Title className="title-box title-box--center ff--1" content={"AKTUALITY"}>
              </Title>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--60" name={"aktuality"}>
          
          <ColumnWrap className="column__flex --center el--2 flex--top" style={{"maxWidth":1122,"paddingTop":0}} columns={"2"}>
            
            <ColumnWrapper className="--left" style={{"paddingTop":0}}>
              
              <Title className="title-box ff--1 fs--18 swpf--uppercase" style={{"paddingTop":10}} content={"<span style=\"color: var(--black);\">KE KAŽDÉMU VYŠETŘENÍ JE NUTNÉ SE OBJEDNAT!</span>"}>
              </Title>

              <Text className="text-box fs--18" style={{"maxWidth":""}} content={"<span style=\"color: var(--black); font-weight: normal;\">Akutní nemocné ošetřuji přednostně na počátku ordinační doby: 7:45-9:00; resp. pondělí 13:00 - 15:00 nebo dle předchozí domluvy, v ostatním čase prohlídky apod.</span>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="--left" style={{"paddingTop":0}}>
              
              <Title className="title-box ff--1 fs--18 swpf--uppercase" style={{"paddingTop":10}} content={"<span style=\"color: rgb(199, 0, 0);\">ZÁSTUP V DOBĚ DOVOLENÉ</span>"}>
              </Title>

              <Text className="text-box fs--18" style={{"maxWidth":""}} content={"<span style=\"color: var(--black); font-weight: normal;\">Akutní stavy vyžadující osobní vyšetření<br>(a PN )- ošetří </span><span style=\"color: var(--black);\">MUDr. Pavel Poneš</span><span style=\"color: var(--black); font-weight: normal;\">, ve svých ordinačních hodinách nejlépe po předchozí konzultaci.<br>Tel.: 777 900 676</span><br>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="--left">
              
              <Title className="title-box ff--1 fs--18 swpf--uppercase" content={"<span style=\"color: var(--black);\">PŘIJÍMÁME NOVÉ PACIENTY</span>"}>
              </Title>

              <Text className="text-box fs--18" style={{"maxWidth":""}} content={"<span style=\"color: var(--black); font-weight: normal;\">Akutní nemocné ošetřuji přednostně na počátku ordinační doby: 7:45-9:00; resp. pondělí 13-15:00&nbsp;<br></span>Naléhavé stavy přednostně po celou dobu ordinačních hodin.<br>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="--left">
              
              <Title className="title-box ff--1 fs--18 swpf--uppercase" content={"<span style=\"color: var(--black);\">OČKOVÁNÍ</span>"}>
              </Title>

              <Text className="text-box fs--18" style={{"maxWidth":""}} content={"<span style=\"color: var(--black); font-weight: normal;\">očkování proti chřipce&nbsp; září až prosinec Út, St, Čt, Pá 8:00-10:30 - a nebo dle předchozí domluvy.&nbsp;</span><br>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="--left">
              
              <Title className="title-box ff--1 fs--18 swpf--uppercase" content={"<span style=\"color: var(--black);\">PŘIJÍMÁME NOVÉ PACIENTY</span>"}>
              </Title>

              <Text className="text-box fs--18" style={{"maxWidth":""}} content={"<span style=\"color: var(--black); font-weight: normal;\">Přednostně pacienty bez lékaře a z Králova Pole a okolí</span><br>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="--left">
              
              <Title className="title-box ff--1 fs--18 swpf--uppercase" content={"<span style=\"color: var(--black);\">NESCHOPENKY</span>"}>
              </Title>

              <Text className="text-box fs--18" style={{"maxWidth":""}} content={"<span style=\"font-weight: normal; color: var(--black);\">Prosím je nutné hlásit ohledně trvání, pokud nebyla kontrola pevně stanovena , a to nejpozději před koncem měsíce ohledně pokračování a lísku na peníze.</span><br>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="--left">
              
              <Title className="title-box ff--1 fs--18 swpf--uppercase" content={"<span style=\"color: rgb(183, 0, 0);\">akutní pacienti</span>"}>
              </Title>

              <Text className="text-box fs--18" style={{"maxWidth":""}} content={"<span style=\"color: var(--black);\"><span style=\"font-weight: normal;\">7:45 - 9:30, resp. pondělí 13:00 - 15:00.</span><br><span style=\"font-weight: normal;\">Prosíme o trpělivost, telefony zvoní nepřetržitě (hlásí stanice obsazena- telefonujeme), snažíme se všechny požadavky dle naléhavosti vyřídit.</span><br>Ke každému vyšetření je nutné se předem k vyšetření objednat<br></span><span style=\"color: var(--black); font-weight: normal;\">telefonicky (541 218 456 ), zvláště pacienti s příznaky respirační infekce&nbsp;</span><br>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="--left">
              
              <Title className="title-box ff--1 fs--18 swpf--uppercase" content={"<span style=\"color: rgb(0, 0, 0);\">EPIDEMIE RESPIRAČNÍCH ONEMOCNĚNÍ</span><br>"}>
              </Title>

              <Text className="text-box fs--18" style={{"maxWidth":""}} content={"<span style=\"color: var(--black); font-weight: normal;\">Vzhledem k epidemii respiračních onemocnění\nakutní nemocní pacienti: ošetření 8-9:20 ( pondělí 13:00-15:00), bez podezření na infekci ( pondělí 15:00-18:00).<br>Nejlépe vždy předem volejte, ať najdeme vhodný termín vyšetření či řešení Vašich potíží.&nbsp;</span><br>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="--left">
              
              <Title className="title-box ff--1 fs--18 swpf--uppercase" content={"<span style=\"color: rgb(0, 0, 0);\">MOŽNOST OBJEDNÁNÍ NA ČAS</span><br>"}>
              </Title>

              <Text className="text-box fs--18" style={{"maxWidth":""}} content={"<span style=\"color: var(--black); font-weight: normal;\">Máme smlouvy se všemi zdravotními pojišťovnami a provádíme ze zdravotního pojištění hrazenou diagnostiku a léčbu běžných onemocnění, pravidelné preventivní prohlídky, dále dispenzární péči chronických onemocnění a řadu dalších vyšetření.</span><br>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="--left">
              
              <Title className="title-box ff--1 fs--18 swpf--uppercase" content={"<span style=\"color: var(--black);\">E-RECEPT NA MOBIL NEBO E-MAIL</span>"}>
              </Title>

              <Text className="text-box fs--18" style={{"maxWidth":""}} content={"<span style=\"font-weight: normal; color: var(--black);\">&nbsp;Využijte možnost zaslání e-receptu na mobil nebo e-mail!<br>Po telefonickém objednání léků, které pravidelně užíváte, Vám přijde kód na mobil - tento ukážete v lékárně a vyzvednete léky.<br>Ušetříte svůj čas, nemusíte na středisko.<br>Telefon do ordinace </span><span style=\"color: var(--black);\">+420 541 218 456</span><br>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="--left">
              
              <Title className="title-box ff--1 fs--18 swpf--uppercase" content={"<span style=\"color: var(--black);\">TELEFONICKÉ KONZULTACE S LÉKAŘKOU</span>"}>
              </Title>

              <Text className="text-box fs--18" style={{"maxWidth":""}} content={"<span style=\"font-weight: normal; color: var(--black);\">Výsledky apod. nejlépe 10:30-11:15, resp. 16:30-17:15),\nv jiné časy vyšetřuji a vyřizuji požadavky.&nbsp;</span><br>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="--left">
              
              <Title className="title-box ff--1 fs--18 swpf--uppercase" content={"<span style=\"color: var(--black);\">ODBĚRY</span>"}>
              </Title>

              <Text className="text-box fs--18" style={{"maxWidth":""}} content={"<span style=\"color: var(--black);\"><span style=\"font-weight: normal;\">odběry 7:00-8:00<br>akutní nemocní pacienti,  PN: 8:00-9:15, resp. pondělí 13:00-15:00  \nobjednaní (prohlídky, kontroly) 9:30-11:00,<br>resp. pondělí 15:00-17:30&nbsp;<br></span>Pokud možno, vždy se předem objednejte</span><br>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--40 pt--40" style={{"backgroundColor":"rgba(196, 232, 188, 1)"}} name={"paticka"}>
          
          <ColumnWrap className="column__flex el--3 flex--top" columns={"3"}>
            
            <ColumnWrapper className="--left">
              
              <Title className="title-box ff--1 fs--26 title-box--invert" content={"<span style=\"color: rgb(0, 0, 0);\">INFORMACE</span>"}>
              </Title>

              <Text className="text-box fs--18 text-box--invert" content={"<span style=\"color: rgb(0, 0, 0);\">www.iprokopcova.cz</span>"}>
              </Text>

              <Text className="text-box fs--16" content={"<a href=\"https://www.facebook.com/iprokopcova.cz/\" style=\"color: var(--black);\">facebook.com</a>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="--center">
            </ColumnWrapper>

            <ColumnWrapper className="--right">
              
              <Title className="title-box ff--1 fs--26 w--500 title-box--invert" content={"<span style=\"color: rgb(0, 0, 0); font-weight: bold;\">KONTAKT</span>"}>
              </Title>

              <Subtitle className="subtitle-box fs--16 subtitle-box--invert" content={"<span style=\"color: rgb(0, 0, 0);\">Telefon:</span>"}>
              </Subtitle>

              <Text className="text-box fs--18 w--600 text-box--invert mt--0" content={"<span style=\"color: rgb(0, 0, 0);\">+420 541 218 456</span>"}>
              </Text>

              <Subtitle className="subtitle-box fs--16 subtitle-box--invert" content={"<span style=\"color: rgb(0, 0, 0);\">E-mail:</span>"}>
              </Subtitle>

              <Text className="text-box fs--18 w--600 text-box--invert mt--0" content={"<span style=\"color: rgb(0, 0, 0);\">ordinace@iprokopcova.cz</span>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>

      </Layout>
    </ThemeWrapper>
  )
}